import * as THREE from 'three';
import Experience from "../Experience";

export default class Lustre
{
    constructor(scene)
    {
        this.experience = new Experience()
        this.scene = scene
        this.resources = this.experience.resources
        this.environmentMap = this.experience.environmentMap

        // Setup
        this.resource = this.resources.items.lustre

        //this.setTextures()
        this.setMaterial()
        this.setModel()
    }

    setTextures()
    {
        this.textures = {}

        this.textures.normalMap = this.resources.items.lustreMap
    
    }

    setMaterial()
    {
        this.material = new THREE.MeshPhysicalMaterial({ color: 0xffffff })
        //this.material.transparent = true
        //this.material.opacity = 0.3
        this.material.metalness = 0
        this.material.roughness = 0
        this.material.transmission = 1.0
        this.material.thickness = 0.2
        this.material.reflectivity = 1.0
        this.material.sheen = 0.8
        this.material.ior = 2.3
    }

    setModel()
    {
        this.model = this.resource.scene
        this.model.scale.set(1, 1, 1)
        this.model.position.set(0, -0.8, 0)
        this.model.rotation.y = Math.PI
        this.model.traverse((child) =>
        {
            child.material = this.material
        })
        
        //this.textures.normalMap.flipY = false;
        //this.textures.normalMap.encoding = THREE.sRGBEncoding
        this.scene.add(this.model)
    }
}