import * as THREE from 'three';
import Experience from './Experience';
import { gsap } from 'gsap';

const tl = gsap.timeline();
gsap.registerPlugin();

export default class Interactions
{
    constructor(scene)
    {
        this.experience = new Experience()
        this.scene = scene
        this.canvas = this.experience.canvas
        this.camera = this.experience.makeScene.camera
        this.controls = this.experience.makeScene.controls
        this.resources = this.experience.resources

        // Setup
        this.mouse = new THREE.Vector2()
        this.infoProductCube = document.querySelector(".left")
        this.closePoint = document.querySelector('.closePoint')

        this.material1 = document.querySelector('.mat1')
        this.material2 = document.querySelector('.mat2')
        this.material3 = document.querySelector('.mat3')
        this.material4 = document.querySelector('.mat4')

        this.wood4 = document.querySelector('.ds1964')
        this.foot1 = document.querySelector('.ds1961Foot')
        this.foot2 = document.querySelector('.ds1962Foot')
        this.foot3 = document.querySelector('.ds1963Foot')
        this.foot4 = document.querySelector('.ds1964Foot')

        window.addEventListener('mousemove', (event) =>
        {
            this.mouse.x = event.clientX / this.canvas.clientWidth * 2 - 1
            this.mouse.y = - (event.clientY / this.canvas.clientHeight) * 2 + 1
        })


        // Targets
        //this.setTargets()
        //this.setRaycaster()
        //this.closeInfoProd()
        this.currentMaterial()
        //this.currentMaterialFoot()
    }

    setTargets()
    {
        this.cubeTarget = new THREE.Mesh
        (
            new THREE.BoxGeometry(.5, 1, .5),
            new THREE.MeshBasicMaterial({color: 0xff0000, wireframe: true})
        )
        this.cubeTarget.name = "targetBoxRed"
        this.cubeTarget.visible = false;
        this.cubeTarget.position.set(0.6823, -0.5, 1.5222)

        // Debug
        //if(this.debug.active)
        //{
        
        //    //this.debugFolder.add(this.cubeTarget.position, 'y').min(-5).max(5).step(0.0001).name('targetY')
        //    //this.debugFolder.add(this.cubeTarget.position, 'z').min(-5).max(5).step(0.0001).name('targetZ')
        //}
        
        this.startPoint = new THREE.Mesh
        (
            new THREE.BoxGeometry(1, 1, 0.3),
            new THREE.MeshBasicMaterial({color: 0x0000ff, wireframe: true})
        )
        this.startPoint.name = "targetBoxBlue"
        this.startPoint.visible = false;
        this.startPoint.position.set(-1.5, 0.8, -0.8)
        
        this.scene.add(this.cubeTarget,this.startPoint);
    }

    setRaycaster()
    {
        

        this.raycaster = new THREE.Raycaster()
        this.currentIntersect = {};
        
        window.addEventListener('dblclick', (event) =>
        {
            
           if(this.currentIntersect)
           {
               switch(this.currentIntersect.object)
               {
                   case this.cubeTarget:
                    console.log('case')
                    //this.infoProductCube.classList.toggle("openProductInfo");
                    this.camera.position.set(0,0.5,0);
                    this.camera.controls.target = (this.cubeTarget.position);
                    this.startPoint.position.set(-1.5, 0.8, -0.8)
                    console.log('ok')
                    break
                   
                    case this.startPoint:
                    //this.infoProductCube.classList.toggle("openProductInfo");
                    this.camera.position.set(-1.2,0.8,0.8);
                    this.camera.controls.target = (this.startPoint.position);
                    this.cubeTarget.position.set(0.6823, -0.5, 1.5222)
                    break

               }
            } else {
                     //this.infoProductCube.classList.remove("openProductInfo")
                     this.camera.controls.target = (new THREE.Vector3(0,0,4.5))
                     this.camera.controls.reset()
                     this.cubeTarget.position.set(0.6823, -0.5, 1.5222)
                     this.startPoint.position.set(-1.5, 0.8, -0.8)
                    }
        })
    }

    closeInfoProd()
    {
        this.closePoint.addEventListener('click', (event) => {
            this.infoProductCube.classList.remove("openProductInfo")
            this.camera.controls.target = (new THREE.Vector3(0,0,4.5))
            this.camera.controls.reset()
            this.cubeTarget.position.set(0.6823, -0.5, 1.5222)
            this.startPoint.position.set(-1.5, 0.8, -0.8)
        })
    }

    currentMaterial()
    {
        
        this.material1.addEventListener('click', (event) => {
            console.log('ok')
            const platoMat = this.resources.items.dc10Frame.scene.children.find((child) => child.name === "ChaiseDC10")
            platoMat.material = this.experience.chair.material1
            this.material1.classList.toggle('active')
            this.material2.classList.remove('active')
            this.material3.classList.remove('active')
            this.material4.classList.remove('active')
            
        })
        this.material2.addEventListener('click', (event) => {
            const platoMat = this.resources.items.dc10Frame.scene.children.find((child) => child.name === "ChaiseDC10")
            platoMat.material = this.experience.chair.material2
            this.material1.classList.remove('active')
            this.material2.classList.toggle('active')
            this.material3.classList.remove('active')
            this.material4.classList.remove('active')
        })
        this.material3.addEventListener('click', (event) => {
            const platoMat = this.resources.items.dc10Frame.scene.children.find((child) => child.name === "ChaiseDC10")
            platoMat.material = this.experience.chair.material3
            this.material1.classList.remove('active')
            this.material2.classList.remove('active')
            this.material3.classList.toggle('active')
            this.material4.classList.remove('active')
        })
        this.material4.addEventListener('click', (event) => {
            const platoMat = this.resources.items.dc10Frame.scene.children.find((child) => child.name === "ChaiseDC10")
            platoMat.material = this.experience.chair.material4
            this.material1.classList.remove('active')
            this.material2.classList.remove('active')
            this.material3.classList.remove('active')
            this.material4.classList.toggle('active')
        })
    }

    currentMaterialFoot()
    {
        
        this.foot1.addEventListener('click', (event) => {
            const piedMat = this.resources.items.picasso.scene.children.find((child) => child.name === "SM_DS_196_Pied001")
            piedMat.material = this.experience.world.picasso.material2
            this.foot1.classList.toggle('active')
            this.foot2.classList.remove('active')
            this.foot3.classList.remove('active')
            this.foot4.classList.remove('active')
        })
        this.foot2.addEventListener('click', (event) => {
            const platoMat = this.resources.items.picasso.scene.children.find((child) => child.name === "SM_DS_196_Pied001")
            platoMat.material = this.experience.world.picasso.material4
            this.foot1.classList.remove('active')
            this.foot2.classList.toggle('active')
            this.foot3.classList.remove('active')
            this.foot4.classList.remove('active')
        })
        this.foot3.addEventListener('click', (event) => {
            const platoMat = this.resources.items.picasso.scene.children.find((child) => child.name === "SM_DS_196_Pied001")
            platoMat.material = this.experience.world.picasso.material1
            this.foot1.classList.remove('active')
            this.foot2.classList.remove('active')
            this.foot3.classList.toggle('active')
            this.foot4.classList.remove('active')
        })
        this.foot4.addEventListener('click', (event) => {
            const platoMat = this.resources.items.picasso.scene.children.find((child) => child.name === "SM_DS_196_Pied001")
            platoMat.material = this.experience.world.picasso.material3
            this.foot1.classList.remove('active')
            this.foot2.classList.remove('active')
            this.foot3.classList.remove('active')
            this.foot4.classList.toggle('active')
        })
    }

    update()
    {
        this.raycaster.setFromCamera(this.mouse, this.camera)
    
        const objectsToTest = [this.cubeTarget, this.startPoint]
        const intersects = this.raycaster.intersectObjects(objectsToTest)
    
        if(intersects.length)
        {
            if(!this.currentIntersect)
            {
                //console.log("in")
            }
          
            this.currentIntersect = intersects[0]
        }
        else
        {
            if(this.currentIntersect)
            {
                //console.log("out")
            }

            this.currentIntersect = null
        }
    }
}