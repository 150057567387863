import * as THREE from 'three';
import Experience from '../Experience';

export default class Dc10Frame
{
    constructor(scene)
    {
        this.experience = new Experience()
        this.scene = scene
        this.resources = this.experience.resources
        
        // Setup
        this.resource = this.resources.items.dc10Frame
        this.setTextures()
        this.setMaterial()
        this.setModel()
    }

    setTextures()
    {
        this.textures = {}
        this.textures.map = this.resources.items.dc10FrameTexture
    }

    setMaterial()
    {
        this.material = new THREE.MeshBasicMaterial({ map: this.textures.map })

        // Frame materials
        this.material1 = new THREE.MeshBasicMaterial({ map: this.textures.map });
        this.material2 = new THREE.MeshBasicMaterial({ color: 0xf0000f });
        this.material3 = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
        this.material4 = new THREE.MeshBasicMaterial({ color: 0x0eff2b });
    }

    setModel()
    {
        this.model = this.resource.scene
        this.model.scale.set(1.2, 1.2, 1.2)
        this.model.position.set(0.0, -0.5, 0.0)
        //this.model.rotation.y = Math.PI / 2
        this.model.traverse((child) =>
        {
            child.material = this.material
        })
        this.textures.map.flipY = false;
        this.textures.map.encoding = THREE.sRGBEncoding
        this.scene.add(this.model)
    }
}