import Experience from "./Experience";

export default class AddScene
{
    constructor()
    {
        this.experience = new Experience()
        this.sceneElements = this.experience.sceneElements
    }

    addScene( elem, fn )
    {
        this.elem = elem
        this.fn = fn
        this.sceneElements.push( { elem, fn } );
    }

}