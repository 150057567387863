import * as THREE from 'three';
import Experience from '../Experience';

export default class DupontBriquet
{
    constructor(scene)
    {
        this.experience = new Experience()
        this.scene = scene
        this.resources = this.experience.resources

        // Setup
        this.resource = this.resources.items.dupontBriquet
        this.setTextures()
        //this.setMaterial()
        this.setModel()
    }

    setTextures()
    {
        this.textures = {}
        this.textures.map = this.resources.items.dupontBriquetMaterials
    }

    setMaterial()
    {
        this.material = new THREE.MeshPhysicalMaterial({ color: 'grey'})
    }

    setModel()
    {
        this.model = this.resource.scene
        this.model.scale.set(15.0, 15.0, 15.0)
        this.model.position.set(0.0, 0.0, 0.0)
        this.model.rotation.y = Math.PI / 2
        //this.model.traverse((child) =>
        //{
        //    child.material = this.material
        //})
        
        //this.textures.map.flipY = false;
        //this.textures.map.encoding = THREE.sRGBEncoding
        this.scene.add(this.model)
    }
}