import * as THREE from 'three';
import { TrackballControls } from 'three/examples/jsm/controls/TrackballControls.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import Experience from "./Experience"
import Interactions from './InteractionsUI';

export default class MakeScene
{
    constructor()
    {
        this.experience = new Experience()
    }

    makeScene( elem )
    {
        this.scene = new THREE.Scene();
        //console.log(this.scene)
        this.fov = 45;
        this.aspect = 2; // the canvas default
        this.near = 0.1;
        this.far = 500;
        this.camera = new THREE.PerspectiveCamera( this.fov, this.aspect, this.near, this.far );
        this.camera.position.set( 0, 0, 2 );
        this.camera.lookAt( 0, 0, 0 );
        this.scene.add( this.camera );

        this.controls = new OrbitControls( this.camera, elem );
        this.controls.enableZoom = false;
        //this.controls.minAzimuthAngle = Math.PI +  (Math.PI / 2)
        //this.controls.maxAzimuthAngle = 0

        //this.controls.minPolarAngle = - Math.PI
        //this.controls.maxPolarAngle = Math.PI - (Math.PI / 2)

        this.controls.screenSpacePanning = true;

        this.controls.autoRotateSpeed = 0.5;
        this.controls.autoRotate = false;
        this.controls.enablePan = false;

        this.controls.saveState(0,0,0)
        this.controls.enableDamping = true
        this.controls.update()

        {
            this.color = 0xFFFFFF;
            this.intensity = 1;
            this.light = new THREE.DirectionalLight( this.color, this.intensity );
            this.light.position.set( - 1, 2, 4 );
            this.camera.add( this.light );
        }
        this.interactions = new Interactions(this.scene)

        return { scene : this.scene, camera : this.camera, controls : this.controls, interactions : this.interactions };
    }
}