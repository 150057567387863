import * as THREE from 'three';
import Experience from "../Experience";

export default class Picasso
{
    constructor(scene)
    {

        this.experience = new Experience()
        this.scene = scene
        this.resources = this.experience.resources

        // Setup
        this.resource = this.resources.items.picasso

        this.setTextures()
        this.setMaterial()
        this.setModel()
    }

    setTextures()
    {
        this.textures = {}

        this.textures.map = this.resources.items.picassoBase
        this.textures.map.flipY = false;
        this.textures.map.encoding = THREE.sRGBEncoding
    }

    setMaterial()
    {
        this.material = new THREE.MeshBasicMaterial({ map: this.textures.map })

        // Plateau materials
        this.material1 = new THREE.MeshBasicMaterial({ color: 0xff00e0 });
        this.material2 = new THREE.MeshBasicMaterial({ color: 0xf0000f });
        this.material3 = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
        this.material4 = new THREE.MeshBasicMaterial({ color: 0x0000ff });
    }

    setModel()
    {
        this.model = this.resource.scene
        this.model.scale.set(1, 1, 1)
        this.model.position.set(0, -0.8, 0)
        this.model.rotation.y = Math.PI
        this.model.traverse((child) =>
        {
            child.material = this.material;
            child.material1 = this.material1;
            child.material2 = this.material2;
            child.material3 = this.material3;
            child.material4 = this.material4;
        });

        this.scene.add(this.model)

        //console.log(this.model.children[12].name)

        // Get each object
        //const ds196Plateau = this.model.children.find((child) => child.name === 'SM_DS_196_Plateau001')
        //console.log(ds196Plateau)
    }
}