import Experience from "./Experience"

export default class Render
{
    constructor(interactions)
    {
        this.experience = new Experience()
        this.renderer = this.experience.renderer
        this.sceneElements = this.experience.sceneElements
    }

    render(time)
    {
        time *= 0.001;
        
        this.renderer.setScissorTest( false );
        this.renderer.setClearColor( this.clearColor, 0 );
        this.renderer.clear( true, true );
        this.renderer.setScissorTest( true );

        const transform = `translateY(${window.scrollY}px)`;
        this.renderer.domElement.style.transform = transform;

        for ( const { elem, fn } of this.sceneElements ) {
            
            // get the viewport relative position of this element
            const rect = elem.getBoundingClientRect();
            const { left, right, top, bottom, width, height } = rect;
            const isOffscreen =
            bottom < 0 ||
            top > this.renderer.domElement.clientHeight ||
            right < 0 ||
            left > this.renderer.domElement.clientWidth;
            if ( ! isOffscreen ) {
                const positiveYUpBottom = this.renderer.domElement.clientHeight - bottom;
                this.renderer.setScissor( left, positiveYUpBottom, width, height );
                this.renderer.setViewport( left, positiveYUpBottom, width, height );
                fn( time, rect );

            }
            if(this.resizer.resizeRendererToDisplaySize( this.renderer )) {
                this.makeScene.camera.aspect = this.canvas.clientWidth / this.canvas.clientHeight;
                this.makeScene.camera.updateProjectionMatrix();
            };
        }

        //this.material.uniforms.time.value = this.time;  /* shader */
        
        window.requestAnimationFrame( this.render.render.bind(this) );
    }
}