import * as THREE from 'three';
import Experience from "../Experience";

export default class Environment
{
    constructor()
    {
        this.experience = new Experience()
        this.resources = this.experience.resources

        this.setEnvironmentMap()
    }

    setEnvironmentMap()
    {
        this.environmentMap = {}
        this.environmentMap.intensity = 3
        this.environmentMap.texture = this.resources.items.environmentMapTexture
        this.environmentMap.texture.encoding = THREE.sRGBEncoding
        
        this.experience.scene.environment = this.environmentMap.texture

        this.environmentMap.updateMaterials = () =>
        {
            this.experience.scene.traverse((child) =>
            {
                if(child instanceof THREE.Mesh && child.material instanceof THREE.MeshPhysicalMaterial)
                {
                    child.material.envMapIntensity = this.environmentMap.intensity
                    child.material.envMap = this.environmentMap.texture
                    child.material.needsUpdate = true
                }
            })
        }
        this.environmentMap.updateMaterials()
    }
}