export default [
    {
        name: 'environmentMapTexture',
        type: 'cubeTexture',
        path:
        [
            '/envMapTextures/px.png',
            '/envMapTextures/nx.png',
            '/envMapTextures/py.png',
            '/envMapTextures/ny.png',
            '/envMapTextures/pz.png',
            '/envMapTextures/nz.png'
        ]
    },
    {
        name: 'dc10Frame',
        type: 'gltfModel',
        path:
        [
            '/models/chair/glTF/dc10Frame.glb',
        ]
    },
    {
        name: 'dc10FrameTexture',
        type: 'texture',
        path: '/models/chair/glTF/DC10_Frame_4k.jpg'
    },
    {
        name: 'dc10FrameCover',
        type: 'gltfModel',
        path:
        [
            '/models/chair/glTF/dc10Frame_Cover.glb',
        ]
    },
    {
        name: 'dc10FrameCoverTexture',
        type: 'texture',
        path: '/models/chair/glTF/DC10_Frame_Cover_4k.jpg'
    },
    {
        name: 'dupontBriquet',
        type: 'gltfModel',
        path:
        [
            '/models/chair/glTF/Dupont_draco_test_import_03.glb',
        ]
    },
    {
        name: 'basement',
        type: 'gltfModel',
        path:
        [
            '/models/chair/glTF/basement.glb',
        ]
    },
    {
        name: 'whiteBase',
        type: 'texture',
        path: '/models/chair/glTF/Basement_4k.jpg'
    },
    {
        name: 'picasso',
        type: 'gltfModel',
        path:
        [
            '/models/chair/glTF/mobilier1.glb',
        ]
    },
    {
        name: 'picassoBase',
        type: 'texture',
        path: '/models/chair/glTF/mobilier1_2k.jpg'
    },
    {
        name: 'canape',
        type: 'gltfModel',
        path:
        [
            '/models/chair/glTF/canape.glb',
        ]
    },
    {
        name: 'canapeBase',
        type: 'texture',
        path: '/models/chair/glTF/canapeTexture_4k.jpg'
    },
    {
        name: 'lustre',
        type: 'gltfModel',
        path:
        [
            '/models/chair/glTF/lustre.glb',
        ]
    },
    {
        name: 'lustreMap',
        type: 'texture',
        path: '/models/chair/glTF/lustre-2k.jpg'
    },
]