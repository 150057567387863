import * as THREE from 'three';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { gsap } from 'gsap';
import EventEmitter from "./EventEmitter";

export default class Resources extends EventEmitter
{
    constructor(sources)
    {
        super()

        // Options
        this.sources = sources

        // Setup
        this.items = {}
        this.toLoad = this.sources.length
        this.loaded = 0
        this.body = document.querySelector( 'body' );
        this.loaderCtn = document.querySelector( '.loader-ctn' );

        this.setLoaders();
        this.startLoading();
    }

    setLoaders()
    {
        this.loaders = {}

        const loadingBarElement = document.querySelector(".loading-bar");
        const number = document.querySelector(".loading-bar-number");
        const startBtn = document.querySelector('.starting__page');

        this.loaders.loadingManager = new THREE.LoadingManager(
            () =>
            {
                window.setTimeout(() => 
                {
                   loadingBarElement.classList.add("ended")
                   loadingBarElement.style.transform = ""
                   number.classList.add("ended")
                   number.textContent = ""
                }, 1500)
                /*
                gsap.to('.start--btn', {
                    opacity: 1,
                    duration: 1,
                    ease: 'inOut'
                })
                */
            },
            (itemUrl, itemsLoaded, itemsTotal) =>
            {
               const progressRatio = itemsLoaded / itemsTotal
               loadingBarElement.style.transform = `scaleX(${progressRatio})`
               number.textContent = `Loading: ${Math.round(progressRatio * 100)}%`
               if(progressRatio == 1) {
                   window.setTimeout(() => 
                   {
                        this.loaderCtn.classList.toggle('hideLoaderCtn');
                        startBtn.classList.toggle('show')
                   }, 1500)
               }
            }
        )

        this.loaders.dracoLoader = new DRACOLoader(this.loaders.loadingManager)
        this.loaders.dracoLoader.setDecoderPath('/draco/')
        this.loaders.gltfLoader = new GLTFLoader(this.loaders.loadingManager)
        this.loaders.gltfLoader.setDRACOLoader(this.loaders.dracoLoader)
        this.loaders.textureLoader = new THREE.TextureLoader(this.loaders.loadingManager)
        this.loaders.cubeTextureLoader = new THREE.CubeTextureLoader(this.loaders.loadingManager)
    }

    startLoading()
    {
        for(const source of this.sources)
        {
            if(source.type === 'gltfBin')
            {
                this.loaders.dracoLoader.load(
                    source.path,
                    (file) =>
                    {
                        this.sourceLoaded(source, file)
                    }
                )
            }
            else if(source.type === 'gltfModel')
            {
                this.loaders.gltfLoader.load(
                    source.path,
                    (file) =>
                    {
                        this.sourceLoaded(source, file)
                    }
                )
            } 
            else if(source.type === 'texture')
            {
                this.loaders.textureLoader.load(
                    source.path,
                    (file) =>
                    {
                        this.sourceLoaded(source, file)
                    }
                )
            }
            else if(source.type === 'cubeTexture')
            {
                this.loaders.cubeTextureLoader.load(
                    source.path,
                    (file) =>
                    {
                        this.sourceLoaded(source, file)
                    }
                )
            }
        }
    }

    sourceLoaded(source, file)
    {
        this.items[source.name] = file

        this.loaded++

        if(this.loaded === this.toLoad)
        {
            this.trigger('ready')
        }
    }
}
