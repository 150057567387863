
import * as THREE from 'three';
import sources from './sources'
import Resources from './Utils/Resources'

import vertex from './shaders/vertex.glsl';
import fragment from './shaders/fragment.glsl';

import Dc10Frame from './World/Dc10Frame';
import Dc10FrameCover from './World/Dc10FrameCover';
import DupontBriquet from './World/DupontBriquet';
import Basement from './World/Basement';
import Canape from './World/Canape';
import Picasso from './World/Picasso';
import Lustre from './World/Lustre';
import Interactions from './InteractionsUI';

import Environment from './World/Environment';
import AddScene from './AddScene.js';
import MakeScene from './MakeScene.js';
import Resizer from './Resizer.js';
import Render from './Render.js';

let instance = null;

export default class Experience
{
    constructor()
    {
		// Singleton
        if( instance ) { return instance }
        instance = this;

        // Global Access
        window.experience = this;

		// Setup Global
        this.canvas = document.querySelector( '#c' );
        this.speed = 0;
        this.resources = new Resources(sources);
        this.renderer = new THREE.WebGLRenderer( { antialias: true, canvas: this.canvas, powerPreference: 'high-performance', alpha: true } );
        this.renderer.setPixelRatio( window.devicePixelRatio )
        //this.renderer.useLegacyLights = false
		this.renderer.toneMapping = THREE.ReinhardToneMapping
		this.renderer.toneMappingExposure = 3
		this.renderer.shadowMap.enabled = true
		this.renderer.shadowMap.type = THREE.PCFSoftShadowMap
		this.renderer.outputEncoding = THREE.sRGBEncoding
        this.clearColor = new THREE.Color( '#000' );

        this.sceneElements = [];
        
        this.makeScene = new MakeScene();
        this.addScn = new AddScene();

        this.resizer = new Resizer();
        this.render = new Render();
       
        this.sceneBox =
        {
            'box': ( elem ) => {
                const { scene, camera, controls } = this.makeScene.makeScene(elem);

                this.resources.on('ready', () =>
                {
                    this.camera = camera
                    this.scene = scene
                    this.chair = new Dc10Frame(this.scene);
                    this.chairCover = new Dc10FrameCover(this.scene);
                    this.interactions = new Interactions(this.scene);
                })
                return ( time, rect ) => {
                    camera.aspect = rect.width / rect.height;
                    camera.updateProjectionMatrix();
                    controls.minPolarAngle = - Math.PI
                    controls.maxPolarAngle = Math.PI - (Math.PI / 2)
                    controls.autoRotate = true;
                    controls.update();
                    this.renderer.render( scene, camera );
    
                };
            },
            
        };

        this.scenePyramid =
        {
            
            'pyramid': ( elem ) => {
                const { scene, camera, controls } = this.makeScene.makeScene(elem);
                this.resources.on('ready', () =>
                {
                    this.scene = scene
                    this.lighter = new DupontBriquet(this.scene);
                    this.environment = new Environment(this.scene)
                })
                return ( time, rect ) => {
    
                    camera.aspect = rect.width / rect.height;
                    camera.updateProjectionMatrix();
                    controls.update();
                    this.renderer.render( scene, camera );
    
                };
            },
            
        };

        this.sceneCone =
        {
            
            'cone': ( elem ) => { 
                const { scene, camera, controls, interactions } = this.makeScene.makeScene(elem);
                this.resources.on('ready', () =>
                {
                    this.scene = scene
                    this.basementArchi = new Basement(this.scene)
                    this.canape = new Canape(this.scene)
                    this.picasso = new Picasso(this.scene)
                    this.lustre = new Lustre(this.scene)
                    this.environment = new Environment(this.scene)
                })
                camera.position.set( -1.5, 0, 4 );
                return ( time, rect ) => {
                    camera.aspect = rect.width / rect.height;
                    camera.updateProjectionMatrix();
                    controls.minPolarAngle = - Math.PI
                    controls.maxPolarAngle = Math.PI - (Math.PI / 2)
                    controls.minAzimuthAngle = Math.PI +  (Math.PI / 2)
                    controls.maxAzimuthAngle = 0
                    controls.enablePan = true;
                    controls.enableDamping = true;
                    controls.update();
                    //interactions.update();
                    this.renderer.render( scene, camera );
    
                };
            },
            
        };

        this.sceneTri =
        {
            'tri': ( elem ) => {
                const { scene, camera, controls } = this.makeScene.makeScene(elem);
                this.geometry = new THREE.PlaneGeometry(1.0, 1.0, 20, 20);
                //this.geometry = new THREE.BufferGeometry();

                this.blenderLogo = new THREE.TextureLoader().load('/img/blender_icon_1024x1024.png')
                this.material = new THREE.ShaderMaterial({
                    uniforms:{
                        progress: { type: "f", value:0 },
                        time: { type: "f", value: 0 },
                        uTexture: { value: this.blenderLogo }
                    },
                    vertexShader: vertex,
                    fragmentShader: fragment,
                    side: THREE.DoubleSide,
                    transparent: true,
                    depthTest: false,
                    depthWrite: true
                });
                this.mesh = new THREE.Points( this.geometry, this.material );
                scene.add(this.mesh)
                return ( time, rect ) => {
    
                    this.mesh.rotation.y = time * .1;
                    camera.aspect = rect.width / rect.height;
                    camera.updateProjectionMatrix();
                    controls.update();
                    this.renderer.render( scene, camera );
    
                };
            },
            
        };

        this.objBoxA = [document.getElementById('boxA')].forEach((elem) =>
        {
            const sceneNameForBoxA =  elem.dataset.diagram
            const initMyFunction = this.sceneBox[ sceneNameForBoxA ]
            const triggerMyFunction = initMyFunction( elem )
            this.addScn.addScene(elem, triggerMyFunction)
        })
        this.objPyramA = [document.getElementById('pyramidA')].forEach((elem) =>
        {
            const sceneNameForObjPyramA =  elem.dataset.diagram
            const initMyFunction = this.scenePyramid[ sceneNameForObjPyramA ]
            const triggerMyFunction = initMyFunction( elem )
            this.addScn.addScene(elem, triggerMyFunction)
        })
        this.objConeA = [document.getElementById('coneA')].forEach((elem) =>
        {
            const sceneNameForConeA =  elem.dataset.diagram
            const initMyFunction = this.sceneCone[ sceneNameForConeA ]
            const triggerMyFunction = initMyFunction( elem )
            this.addScn.addScene(elem, triggerMyFunction)
        })
        this.objTriA = [document.getElementById('triA')].forEach((elem) =>
        {
            const sceneNameForTriA =  elem.dataset.diagram
            const initMyFunction = this.sceneTri[ sceneNameForTriA ]
            const triggerMyFunction = initMyFunction( elem )
            this.addScn.addScene(elem, triggerMyFunction)
        })

        window.requestAnimationFrame( this.render.render.bind(this) );
    }
};
