import * as THREE from 'three';
import Experience from "./Experience"

export default class Resizer
{
    constructor()
    {
        this.experience = new Experience()
        this.renderer = this.experience.renderer
        this.canvas = this.experience.canvas
    }

    resizeRendererToDisplaySize( renderer )
    {
        this.width = this.canvas.clientWidth;
        this.height = this.canvas.clientHeight;
        this.needResize = this.canvas.width !== this.width || this.canvas.height !== this.height;
        if ( this.needResize ) {

            this.renderer.setSize( this.width, this.height, false );

        }
        return this.needResize;
    }
}