import './styles/modern-normalize.css';
import './style.css';
import './styles/components/starting.css';
import './styles/components/header.css';
import './styles/components/hero.css';
import './styles/components/about.css';
import './styles/components/design2d.css';
import './styles/components/design3d.css';
import './styles/components/chair3d.css';
import './styles/components/mobile-nav.css';
import './styles/components/footer.css';
import './styles/utils.css';

import mobileNav from './Experience/Utils/mobile-nav';
import darkMode from './Experience/Utils/darkMode';
import lazyLoading from './Experience/Utils/lazy-loading';
mobileNav()
darkMode();
lazyLoading();

import Experience from './Experience/Experience';
const experience = new Experience(document.getElementById( 'c' ));
const experienceStart = document.querySelector('main')
const startBtn = document.querySelector('.starting__page');
const headerStart = document.querySelector('.header');
const footerStart = document.querySelector('footer');
const mobileNavStart = document.querySelector('.mobile-nav');

const tracker = document.querySelector('.tracker')

startBtn.addEventListener('click', () => {
     experienceStart.classList.toggle('showMain');
     headerStart.classList.toggle('showHeader');
     mobileNavStart.classList.toggle('showMobileNav');
     footerStart.classList.toggle('showFooter');
     startBtn.remove();
})
/*
const pos = { x : 0, y : 0 };

startBtn.addEventListener('mousemove', e => { saveCursorPosition(e.clientX, e.clientY); })

const saveCursorPosition = function(x, y) {
pos.x = (x / window.innerWidth).toFixed(2);
pos.y = (y / window.innerHeight).toFixed(2);

tracker.textContent = `X: ${(pos.x)}`;
}
console.log(startBtn.classList.length)
*/
/*
image.addEventListener('click', function(e){ 
     image.requestFullscreen(); 
 }) 
 
 image.addEventListener('dblclick', function(e){ 
      document.exitFullscreen(); 
})
*/
/*
const toggle = document.querySelector( '.toggle' );
const header = document.querySelector(".header");
const footer = document.querySelector(".footer");
const about = document.querySelector( '.about' );
const container = document.querySelector(".container");


const loaderCtn = document.querySelector( '.loader-ctn' );
const showPoint = document.querySelector( '.point' );
const portfolio = document.querySelector('.menu--left--inner--item--small');
*/

/*ScrollTrigger.defaults({ignoreMobileResize: true})*/
